

import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Routes from './routes'
import { Provider } from 'react-redux'
import { createStore, combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { routes, generateRoutes } from './routesList';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { langs } from './langs';
import { withRouter } from 'react-router-dom';
import DocumentMeta from 'react-document-meta';
import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3'
import { ReCaptchaSiteKey } from './constants';
import { Input, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Helmet } from "react-helmet";
import ToggleSwitch from './components/forms/fields/toggleSwitch/toggleSwtich';
import Cookies from './cookies';
import TrackUrl from './TrackUrl';
let browserInfo = require('browser-info');

const rootReducer = combineReducers({
  form: formReducer
});

const store = createStore(rootReducer)



function generateAlias(str) {
  str = str.toLowerCase();
  str = str.replace(/ä/g, 'a');
  str = str.replace(/ö/g, 'o');
  str = str.replace(/ü/g, 'u');
  str = str.replace(/ß/g, 'b');

  str = str.replace(/[^a-zA-Z0-9]/gi, '-').toLowerCase()
  str = str.replace(/-+/g, '-');

  return str;
}

if (String.prototype.generateAlias == null) {
  String.prototype.generateAlias = function () {
    return generateAlias(this);
  }
}


Object.translate = function (o, s, lang) {
  if (!o) {
    return '';
  }

  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, '');           // strip a leading dot
  var a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o[lang] ? o[lang] : o['en'];
}


Object.get = function (o, s) {
  console.log(o, s)
  if (!o) {
    return null;
  }

  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, '');           // strip a leading dot
  var a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
}


if (String.prototype.translate == null) {
  String.prototype.translate = function (lang) {

    /* if (!localStorage.translate){
       localStorage.translate = JSON.stringify({
         'ba': {
  
         },
         'en': {
  
         }
       });
     }
  
     let obj = JSON.parse(localStorage.translate);
     obj.en[this] = this;
     obj.ba[this] = this;
 
     localStorage.translate = JSON.stringify(obj);
     
     return this;*/

    if (langs[lang] && langs[lang][this])
      return langs[lang][this];
    else return this;
  }
}



class App extends Component {
  constructor(props) {
    super(props);
    this.googleMapsCallback = this.googleMapsCallback.bind(this);
    this.translate = this.translate.bind(this);
    this.setLang = this.setLang.bind(this);
    this.setLightMode = this.setLightMode.bind(this);
    this.allowCookies = this.allowCookies.bind(this);

    this.state = {
      trigger: 0,
      _googleMapsLoaded: false,
      lang: 'en',
      // lang:  this.props.location.pathname?.includes?.('/en') ? 'en' : (typeof window !== 'undefined' && (window.location.origin === 'https://qa.curoflow.uk' || window.location.origin === 'https://curoflow.uk'))
      //   ? 'en' : (typeof window !== 'undefined' && localStorage?.getItem?.('lang')) ? localStorage?.getItem?.('lang') : this.props.location.pathname?.includes?.('/en') ? 'en' : 'se',
      lightMode: 0,
      services: [],
      latestNews: [],
      ...props.initialData,
      allowSubmit: true,
      isMobile: false,
      // isChat: false
      showChat: true,
      linkedIn: true,
      facebook: true,
      leadCookiesFeeder: true,
      clickCease: true,
      // googleCookiesAds: true,
      googleStatisticCookies: true,
    };

  }

  refreshCookies = () => {
    this.setState(prev => ({
      ...prev,
      trigger: (prev?.trigger || 0) + 1
    }));
  }

  changeLang = (lang) => {
    return;
    console.log('entered change lang')
    let _lang = lang ? lang : (this.state.lang && this.state.lang === 'se') ? 'en' : 'se';
    this.setState({ lang: _lang });
    if (typeof window !== 'undefined') localStorage?.setItem?.('lang', lang);
    // console.log('new lang: ', lang)
  }

  setLang(lang) {
    return;
    console.log('entered set lang')
    this.setState({
      lang: lang
    }, () => {
      if (typeof window != 'undefined') {
        localStorage.setItem('lang', lang)
      }
    });
  }
  setLightMode(val) {
    this.setState({
      lightMode: val
    });
  }
  updateMeta = (data) => {
    this.setState({
      metaTags: data
    })
  }

  translate(text) {
    return text;
  }

  homePageZIndexChange = (value) => {
    this.setState({
      homepageZIndex: value
    })
  }

  bookDemo = (value) => {
    this.setState({
      openModalBookDemo: value
    })
  }

  allowCookies(check, type) {
    localStorage.allowCookies = true;
    localStorage.setItem('buttonType', type);

    this.setState({
      cookies: true
    }, () => {
      if (check) {
        this.setCookiesInLocalStorage()
        // this.consentGranted2()
      } else {
        this.setCookiesInLocalStorage()
        // this.consentGranted1()
      }

    });
  }

  allowChangeSettingsCookies() {
    this.setState({
      changeSettings: true
    })
  }

  consentGranted1 = () => {
    localStorage.setItem('analytics_storage', 'true');

    if (!window.gtag) {
      return window.gtag;
    }

    window.gtag('consent', 'update', {
      'analytics_storage': 'granted',
    });
    window.location.reload(true);
  }

  consentGranted2 = () => {
    localStorage.setItem('ad_storage', 'true');
    localStorage.setItem('analytics_storage', 'true');
    localStorage.setItem('functionality_storage', 'true');
    localStorage.setItem('personalization_storage', 'true');
    localStorage.setItem('security_storage', 'true');

    if (!window.gtag) {
      return window.gtag;
    }

    window.gtag('consent', 'update', {
      'ad_storage': 'granted',
      'analytics_storage': 'granted',
      'functionality_storage': 'granted',
      'personalization_storage': 'granted',
      'security_storage': 'granted'

    });
    window.location.reload(true);
  }


  verifyCallbackApp = (token) => {
    this.setState({
      recaptchaToken: token
    }, this.verifyCallback)
  }



  setCookiesInLocalStorage() {
    const cookiesSettings = {
      necessaryCookies: true,
      linkedIn: this.state.linkedIn || false,
      facebook: this.state.facebook || false,
      leadCookiesFeeder: this.state.leadCookiesFeeder || false,
      clickCease: this.state.clickCease || false,
      // googleCookiesAds: this.state.googleCookiesAds || false,
      googleStatisticCookies: this.state.googleStatisticCookies || false,
    };

    // Postavljanje ili brisanje iz localStorage na osnovu vrednosti
    localStorage.setItem('necessaryCookies', cookiesSettings.necessaryCookies);

    if (cookiesSettings.linkedIn) {
      localStorage.setItem('linkedIn', cookiesSettings.linkedIn);
    } else {
      localStorage.removeItem('linkedIn');
    }

    if (cookiesSettings.facebook) {
      localStorage.setItem('facebook', cookiesSettings.facebook);
    } else {
      localStorage.removeItem('facebook');
    }

    if (cookiesSettings.leadCookiesFeeder) {
      localStorage.setItem('leadFeeder', cookiesSettings.leadCookiesFeeder);
    } else {
      localStorage.removeItem('leadFeeder');
    }

    if (cookiesSettings.clickCease) {
      localStorage.setItem('clickCease', cookiesSettings.clickCease);
    } else {
      localStorage.removeItem('clickCease');
    }

    // if (cookiesSettings.googleCookiesAds) {
    //   localStorage.setItem('googleAds', cookiesSettings.googleCookiesAds);
    // } else {
    //   localStorage.removeItem('googleAds');
    // }

    if (cookiesSettings.googleStatisticCookies) {
      localStorage.setItem('googleAnalytics', cookiesSettings.googleStatisticCookies);
    } else {
      localStorage.removeItem('googleAnalytics');
    }

    this.setState({
      changeSettings: false,
      cookies: true
    }, () => {
      this.refreshCookies();
    });

    // window.location.reload(true);
  }



  render() {
    console.log('aaaaaaaaa', typeof window !== 'undefined' && window.location.hostname);

    let meta;

    if (this.state.metaTags) {
      meta = {
        title: this.state.metaTags.title ? this.state.metaTags.title : null,
        description: this.state.metaTags.description ? this.state.metaTags.description : null,
        tags: this.state.metaTags.tags ? this.state.metaTags.tags : null,
        meta: {
          charset: 'utf-8',
          name: {
            'og:title': this.state.metaTags['og:title'] ? this.state.metaTags['og:title'] : null,
            'og:type': this.state.metaTags['og:type'] ? this.state.metaTags['og:type'] : null,
            'og:url': this.state.metaTags['og:url'] ? this.state.metaTags['og:url'] : null,
            'og:image': this.state.metaTags['og:image'] ? this.state.metaTags['og:image'] : null,
            'og:description': this.state.metaTags['og:description'] ? this.state.metaTags['og:description'] : null
          }
        }
      };
    }


    if (typeof window !== 'undefined' && typeof localStorage && (!localStorage.getItem('auth') || localStorage.getItem('auth') !== 'Curoflow') && window.location.hostname === 'qa.curoflow.se') {

      return (

        <div>

          <Modal isOpen={true}>
            <ModalHeader>Password</ModalHeader>
            <ModalBody>
              <Input onChange={(e) => {
                if (e.target.value === 'Curoflow' && localStorage) {
                  localStorage.setItem('auth', e.target.value)
                  window.location.reload(true);
                }

              }} />
            </ModalBody>
          </Modal>

        </div>
      )
    }



    return (

      <div>
        {
          this.state.openModalBookDemo ?
            <div className='overlay overlay-active' onClick={() => {
              this.bookDemo(false)
            }}></div>
            :
            null
        }
        <Provider store={store}>
          {this.state.metaTags ? <DocumentMeta {...meta} /> : null}
          <Routes
            {...this.state}
            translate={this.translate}
            setLang={this.setLang}
            setLightMode={this.setLightMode}
            serverFetch={this.props.serverFetch}
            initialData={this.props.initialData ? this.props.initialData : {}}
            updateMeta={this.updateMeta}
            changeLang={this.changeLang}
            homePageZIndexChange={this.homePageZIndexChange}
            updateToken={() => this.recaptcha.execute()}
            setRecaptchaCallback={(callback) => this.verifyCallback = callback}
            // allowCookies={(check) => {
            //   console.log("pero");
            //   sessionStorage.allowCookies = true;
            //   if (check){
            //     console.log("hhhfhfhfgh");
            //     this.consentGranted2()
            //   }
            //   else{
            //     this.consentGranted1()

            //   }

            //   this.setState({
            //     cookies: true
            //   });
            // }}
            verifyUser={this.verifyUser}
            bookDemo={this.bookDemo}
          />
        </Provider>

        <ReCaptcha
          sitekey={ReCaptchaSiteKey}
          action='submit'
          ref={ref => this.recaptcha = ref}
          verifyCallback={this.verifyCallbackApp}

        />


        {typeof window !== 'undefined' && !this.state.cookies ?
          <div className="cookies-popup">
            <h5>{'Vi använder cookies'.translate(this.state.lang)}</h5>
            <p>{'To optimize your use of our website, Curoflow uses necessary cookies. If you agree, we also use statistics & marketing cookies in order to analyze website traffic and offer more relevant communication. The data is shared with the cookie provider and you can revoke your consent at any time.'.translate(this.state.lang)}</p>
            <div className='buttons'>
              <button className="change-settings" onClick={() => this.allowChangeSettingsCookies()}>{'Change settings'.translate(this.state.lang)}</button>
              <button className="accept-all" onClick={() => this.allowCookies(true, 1)}>{'Acceptera alla'.translate(this.state.lang)}</button>

            </div>
            <span><a href="https://curoflow.se/cookies" target="_blank">{'Läs mer'.translate(this.state.lang)}</a></span>
          </div>

          :
          null
        }



        {typeof window !== 'undefined' && this.state.changeSettings ? (
          <div className='cookies-settings'>
            <h5 className=''>{'Change settings'.translate(this.state.lang)}</h5>
            <div className='on-off'>
              <p>{'off / on'.translate(this.state.lang)}</p>
            </div>
            <ToggleSwitch
              Name='necessaryCookies'
              label={'Necessary cookies'.translate(this.state.lang)}
              checked={true} // Uvek postavljeno na true
              onChange={() => { /* Ovo ne treba menjati, jer je uvek true */ }}
              class={true}
            />
            <h5 className='marketing-cookies'>{'Marketing cookies'.translate(this.state.lang)}</h5>

            <ToggleSwitch
              Name='linkedIn'
              label='LinkedIn'
              checked={this.state.linkedIn}
              onChange={() => this.setState({ linkedIn: !this.state.linkedIn })}
            />
            <ToggleSwitch
              Name='facebook'
              label='Facebook'
              checked={this.state.facebook}
              onChange={() => this.setState({ facebook: !this.state.facebook })}
            />
            <ToggleSwitch
              Name='leadFeeder'
              label='Leadfeeder'
              checked={this.state.leadCookiesFeeder}
              onChange={() => this.setState({ leadCookiesFeeder: !this.state.leadCookiesFeeder })}
            />
            <ToggleSwitch
              Name='clickCease'
              label='ClickCease'
              checked={this.state.clickCease}
              onChange={() => this.setState({ clickCease: !this.state.clickCease })}
            />
            {/* <ToggleSwitch
              Name='googleAds'
              label='Google Ads'
              checked={this.state.googleCookiesAds}
              onChange={() => this.setState({ googleCookiesAds: !this.state.googleCookiesAds })}
            /> */}
            <p className='marketing-cookies'>{'Statistics Cookies2'.translate(this.state.lang)}</p>
            <ToggleSwitch
              Name='googleAnalytics'
              label={'Google services'.translate(this.state.lang)}
              checked={this.state.googleStatisticCookies}
              onChange={() => this.setState({ googleStatisticCookies: !this.state.googleStatisticCookies })}
            />




            <div className='buttons'>
              <button className='back' onClick={() => { this.setState({ changeSettings: false }) }}>{'Back'.translate(this.state.lang)}</button>

              <button className='save' onClick={() => {
                this.allowCookies(true, 1);
                this.setCookiesInLocalStorage();
              }}>{'Save'.translate(this.state.lang)}</button>
              <button className="accept-all" onClick={() => {
                this.allowCookies(true, 1);
                this.setCookiesInLocalStorage();
              }}>{'Acceptera alla'.translate(this.state.lang)}</button>




            </div>
          </div>
        ) : null}

        {this.state.showChat ? <Helmet>
          <script src="https://www.socialintents.com/api/socialintents.1.3.js#2c9faa3587fe49fa018800ef78c901e4" async="async"></script>
        </Helmet> : null}
        <Cookies trigger={this.state.trigger} />
        <TrackUrl />
      </div>

    );

  }

  verifyUser = () => {
    if (typeof localStorage !== 'undefined' && localStorage.token) {
      fetch('http://localhost:4000/user', {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }).then(res => res.json()).then((result) => {
        // console.log(result);
        this.setState({
          user: result
        })
      })
    }
  }

  detectAutomatedTool = () => {
    var documentDetectionKeys = [
      "__webdriver_evaluate",
      "__selenium_evaluate",
      "__webdriver_script_function",
      "__webdriver_script_func",
      "__webdriver_script_fn",
      "__fxdriver_evaluate",
      "__driver_unwrapped",
      "__webdriver_unwrapped",
      "__driver_evaluate",
      "__selenium_unwrapped",
      "__fxdriver_unwrapped",
    ];

    var windowDetectionKeys = [
      "_phantom",
      "__nightmare",
      "_selenium",
      "callPhantom",
      "callSelenium",
      "_Selenium_IDE_Recorder",
    ];

    for (const windowDetectionKey in windowDetectionKeys) {
      const windowDetectionKeyValue = windowDetectionKeys[windowDetectionKey];
      if (window[windowDetectionKeyValue]) {
        return true;
      }
    };
    for (const documentDetectionKey in documentDetectionKeys) {
      const documentDetectionKeyValue = documentDetectionKeys[documentDetectionKey];
      if (window['document'][documentDetectionKeyValue]) {
        return true;
      }
    };

    for (const documentKey in window['document']) {
      if (documentKey.match(/\$[a-z]dc_/) && window['document'][documentKey]['cache_']) {
        return true;
      }
    }

    if (window['external'] && window['external'].toString() && (window['external'].toString()['indexOf']('Sequentum') != -1)) return true;

    if (window['document']['documentElement']['getAttribute']('selenium')) return true;
    if (window['document']['documentElement']['getAttribute']('webdriver')) return true;
    if (window['document']['documentElement']['getAttribute']('driver')) return true;
    if (!window['navigator']) return true;
    if (window['navigator']['webdriver']) return true;

    return false;
  };

  componentDidMount() {
    const pathname = typeof window !== 'undefined' ? (
      window.location?.pathname
    ) : '';
    if (pathname === '/form-submitted') this.setState({ showChat: false });
    else this.setState({ showChat: true })

    if (this.detectAutomatedTool() && (typeof window !== 'undefined' && window.origin.indexOf('localhost') !== -1)) {
      this.setState({ allowSubmit: false });
    }

    if (localStorage.allowCookies) {
      this.setState({
        cookies: true
      });
    }


    if (typeof window != 'undefined') {
      const necessaryCookies = localStorage.getItem('necessaryCookies');
      const linkedIn = localStorage.getItem('linkedIn');
      const facebook = localStorage.getItem('facebook');
      const leadFeeder = localStorage.getItem('leadFeeder');
      const clickCease = localStorage.getItem('clickCease');
      const googleAds = localStorage.getItem('googleAds');
      const statisticsCookies = localStorage.getItem('googleAnalytics');

      this.setState({
        necessaryCookies: necessaryCookies ? necessaryCookies : this.state.necessaryCookies,
        linkedIn: linkedIn ? linkedIn : this.state.linkedIn,
        facebook: facebook ? facebook : this.state.facebook,
        leadFeeder: leadFeeder ? leadFeeder : this.state.leadFeeder,
        clickCease: clickCease ? clickCease : this.state.clickCease,
        googleAds: googleAds ? googleAds : this.state.googleAds,
        statisticsCookies: statisticsCookies ? statisticsCookies : this.state.statisticsCookies,
      });
      // if (localStorage?.getItem?.('lang')) {
      //   this.setState({ lang: localStorage?.getItem?.('lang') });
      // } else if (window.location.pathname?.includes?.('/en')) {
      //   this.setState({ lang: 'en' }, () => {
      //     localStorage?.setItem?.('lang', 'en')
      //   })
      // } else {
      //   this.setState({ lang: 'se' }, () => {
      //     localStorage?.setItem?.('lang', 'se')
      //   })
      // }

      //   if (window.location.pathname === '/') {
      //     console.log(' didm ', localStorage?.getItem?.('lang'));
      //     if (localStorage?.getItem?.('lang')) {
      //       this.setState({ lang: localStorage?.getItem?.('lang') });
      //     } else {
      //       this.setState({ lang: 'se' }, () => {
      //         localStorage?.setItem?.('lang', 'se')
      //       })
      //     }
      //   } else if (window.location.pathname?.includes?.('/en')) {
      //     this.setState({ lang: 'en' }, () => {
      //       localStorage?.setItem?.('lang', 'en')
      //     })
      //   } else {
      //     this.setState({ lang: 'se' }, () => {
      //       localStorage?.setItem?.('lang', 'se')
      //     })
      //   }

      // } else {
      //   if (typeof window != 'undefined') {
      //     localStorage.setItem('lang', this.state.lang)
      //   }
    }

    this.verifyUser();

    //ReactGA.initialize('UA-75168242-1');
    let browserName = browserInfo();
    let userAgent = navigator.userAgent;
    let browserNameUA = 'No browser detection'
    if (userAgent.match(/chrome|chromium|crios/i)) {
      browserNameUA = "Chrome";
    } else if (userAgent.match(/firefox|fxios/i)) {
      browserNameUA = "Firefox";
    } else if (userAgent.match(/safari/i)) {
      browserNameUA = "Safari";
    } else if (userAgent.match(/edg/i)) {
      browserNameUA = "Edge";
    }


    this.setState({
      browserName: browserName.name,
      browserNameUA: browserNameUA
    })


    const mobileKeywords = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

    let osMobileNameUA = 'No os detection'
    if (userAgent.match(/Android/i)) {
      osMobileNameUA = "Android";
    } else if (userAgent.match(/webOS/i)) {
      osMobileNameUA = "webOS";
    } else if (userAgent.match(/iPhone/i)) {
      osMobileNameUA = "iPhone";
    } else if (userAgent.match(/iPad/i)) {
      osMobileNameUA = "iPad";
    } else if (userAgent.match(/iPod/i)) {
      osMobileNameUA = "iPod";
    } else if (userAgent.match(/BlackBerry/i)) {
      osMobileNameUA = "BlackBerry";
    } else if (userAgent.match(/IEMobile/i)) {
      osMobileNameUA = "IEMobile";
    } else if (userAgent.match(/Opera Mini/i)) {
      osMobileNameUA = "Opera Mini";
    }


    console.log('osMobileNameUA', osMobileNameUA);
    let isMobile = false

    if (mobileKeywords.test(userAgent)) {
      isMobile = true
      this.setState({
        isMobile: isMobile,
        osMobileNameUA: osMobileNameUA
      });
    } else {
      this.setState({
        isMobile: isMobile,
        osMobileNameUA: osMobileNameUA
      });
    }

    this.props.history.listen((location, action) => {

    });

    loadReCaptcha(ReCaptchaSiteKey, () => {
    });


    // setTimeout(() => {
    //   this.setState({
    //     isChat: true
    //   })
    // }, 7000)

  }

  componentDidUpdate() {
    // console.log('lang: ', this.state.lang);

    // const pathname = typeof window !== 'undefined' ? (
    //   window.location?.pathname
    // ) : '';
    // if (pathname === '/form-submitted') this.setState({ showChat: false });
    // else this.setState({ showChat: true })
  }


  googleMapsCallback() {
    this.setState({ _googleMapsLoaded: true });
  }


}

export default withRouter(App);
