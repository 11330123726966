import { matchPath } from 'react-router-dom';


const allLinks = [
    '/',
    '/vi-hjalper/:alias',
    // '/vara-losningar/:alias',
    '/var-plattform/:alias',
    '/foretaget/:alias',
    '/integrationer/:alias',
    '/nyheter-om-curoflow-vardplattform',
    '/artiklar-om-mjukvara-for-digital-vard',
    '/artiklar-om-mjukvara-for-digital-vard/:alias',
    '/webdoc-och-curoflow-integreras-for-digifysisk-vard',
    '/gdpr-efterlevnad-i-Curoflow-digital-vardplattform',
    '/kunders-feedback-pa-Curoflow-digital-vardplattform',
    '/app',
    // '/for-partners',
    '/cookies',
    '/integritetspolicy',
    '/kontakta-oss',
    '/thank-you',
    '/unsubscribe/:email',
    '/:alias',
    '/boka-demo',
    '/tjanstebeskrivning'


]

const linkTranslates = {

    'en': {
        '/': '/en',
        '/vi-hjalper/:alias': '/en/we-assist/:alias',
        // '/vara-losningar/:alias': '/en/our-solution/:alias',
        '/integrationer/:alias': '/en/integrations/:alias',
        '/var-plattform/:alias': '/en/our-solution/:alias',
        '/foretaget/:alias': '/en/company/:alias',
        '/artiklar-om-mjukvara-for-digital-vard/:alias': '/en/articles-about-digital-healthcare/:alias',
        '/artiklar-om-mjukvara-for-digital-vard': '/en/articles-about-digital-healthcare',
        '/nyheter-om-curoflow-vardplattform': '/en/news-about-the-curoflow-practice-management-software',
        '/webdoc-och-curoflow-integreras-for-digifysisk-vard': '/en/webdoc-integration-with-curoflow-telemedicine',
        '/gdpr-efterlevnad-i-Curoflow-digital-vardplattform': '/en/gdpr-compliance-in-Curoflow-practice-management-software',
        '/kunders-feedback-pa-Curoflow-digital-vardplattform': '/en/customer-feedback-on-Curoflow-practice-management-software',
        '/app': '/en/app',
        // '/for-partners': '/en/for-partners',
        '/cookies': '/en/cookies',
        '/integritetspolicy': '/en/privacy-policy',
        '/kontakta-oss': '/en/contact-us',
        '/thank-you': '/en/thank-you',
        '/unsubscribe/:email': '/en/unsubscribe/:email',
        '/:alias': '/en/:alias',
        '/boka-demo': '/en/book-demo',
        '/tjanstebeskrivning': '/en/service-description'



    },
    // 'se': {
    //     '/': '/',
    //     '/weassist/:alias': '/vi-hjalper/:alias',
    //     '/our-solution/:alias': '/vara-losningar/:alias',
    //     '/about-us/:alias': '/om-oss/:alias',
    //     '/nyheter-om-curoflow-vårdplattform': '/nyheter-om-curoflow-vårdplattform',
    //     '/news-details': '/specifik-nyhet',
    //     '/gdpr': '/gdpr',
    //     '/customers-tell-us': '/kunder-berattar',
    //     '/app': '/se/app',
    //     '/for-partners': '/se/for-partners',
    //     '/cookies': '/cookies',
    //     '/integritetspolicy': '/integritetspolicy',
    //     '/kontakta-oss': '/kontakta-oss',
    //     '/tack': '/tack',
    //     '/unsubscribe/:email':'/unsubscribe/:email'  
    // },
}

export default function (link, lang) {
    let to = link;
    if (!lang) {
        lang = 'se';
    }
    if (lang !== 'se') {
        allLinks.some(route => {
            const match = matchPath(to, route);
            if (match && match.isExact) {
                to = to.replace(route.split(':')[0], linkTranslates['en'][route].split(':')[0])
            }
            return match && match.isExact;
        });
    }

    return to;
}